var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"options":_vm.dialogOptions.dialog},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.visible)?_c('TableLayout',{ref:"TableLayout",attrs:{"columns":_vm.getColumns,"query-format":_vm.queryFormat,"format-global-query":_vm.formatGlobalQuery,"url":("/" + _vm.$businessISG + "/monitor/staff/staff-page"),"export-option":_vm.isSubbranch
        ? {
            fileName: '预警列表',
            url: ("/" + _vm.$businessISG + "/monitor/home/warn-export"),
          }
        : {}},scopedSlots:_vm._u([{key:"query-form",fn:function(ref){
        var queryParam = ref.queryParam;
return [(_vm.isSubbranch)?[_c('QueryLayout',{attrs:{"lable":"类型"}},[_c('Select',{attrs:{"options":_vm.warnType,"replace-fields":{
              lable: 'msg',
              value: 'code',
            },"placeholder":"请选择类型"},model:{value:(queryParam.warnType),callback:function ($$v) {_vm.$set(queryParam, "warnType", $$v)},expression:"queryParam.warnType"}})],1),_c('QueryLayout',{attrs:{"lable":"状态"}},[_c('Select',{attrs:{"options":_vm.readFlag,"replace-fields":{
              lable: 'msg',
              value: 'code',
            },"placeholder":"请选择状态"},model:{value:(queryParam.readFlag),callback:function ($$v) {_vm.$set(queryParam, "readFlag", $$v)},expression:"queryParam.readFlag"}})],1)]:[_c('QueryLayout',{attrs:{"lable":"机构名称"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            },"placeholder":"请选择机构名称"},model:{value:(queryParam.organizationUUID),callback:function ($$v) {_vm.$set(queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1)],_c('QueryLayout',{attrs:{"lable":"预警时间"}},[_c('DatePicker',{attrs:{"range":""},model:{value:(queryParam.time),callback:function ($$v) {_vm.$set(queryParam, "time", $$v)},expression:"queryParam.time"}})],1)]}},{key:"table-item-times",fn:function(ref){
            var row = ref.row;
return [_vm._v(" 第"+_vm._s(row.times ? row.times : 0)+"次 ")]}},{key:"table-item-action",fn:function(ref){
            var row = ref.row;
return _c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$refs.TaskInfoDialog.openDialog({ row: row })}}},[_c('div',{staticStyle:{"color":"#25c4ff"}},[_vm._v("查看")])])}}],null,false,1347309389)}):_vm._e(),_c('EarlyWarningHandleInfoDialog',{ref:"TaskInfoDialog",on:{"success":function($event){_vm.$refs.TableLayout && _vm.$refs.TableLayout.getData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }