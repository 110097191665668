<template>
  <LayoutBox
    :loading="loading"
    :title="'安保助手预警一览('+total+')'"
    style="
      cursor: pointer;
      transform: rotateY(-6deg) scaleX(1.3) translateY(20px);
      transform-origin: right center;
    "
    @onTitleClick='titleClick'
  >
    <ScrollTable
      ref="ScrollTable"
      :key="refreshKey"
      v-model="loading"
      :columns="getColumns"
      @getTotal="e=> total = e"
      is-global-query
      :query="{
        status: '02',
        handleFlagList: '01',
        isTimeDesc: '02',
      }"
      :url="`/${$businessISG}/monitor/staff/staff-page`"
      @clickRow="
        ({ row }) => $refs.EarlyWarningHandleInfoDialog.openDialog({ row,type:'safeAssistant' })
      "
    ></ScrollTable>
    <EarlyWarningHandleInfoDialog
      ref="EarlyWarningHandleInfoDialog"
      @success="$refs.ScrollTable && $refs.ScrollTable.refresh()"
    ></EarlyWarningHandleInfoDialog>
    <SafeSupervision
      ref="SafeSupervision"
      @success="$refs.ScrollTable && $refs.ScrollTable.refresh()"
    ></SafeSupervision>
  </LayoutBox>
</template>

<script>
import {mapState} from 'vuex';
import EarlyWarningHandleInfoDialog from './EarlyWarningHandleInfoDialog.vue';
import SafeSupervision from "@/views/screen/earlyWarning/modules/SafeSupervision";

export default {
  //组件注册
  components: {
    LayoutBox: () => import('../components/LayoutBox.vue'),
    ScrollTable: () => import('@/views/screen/components/ScrollTable'),
    EarlyWarningHandleInfoDialog,
    SafeSupervision
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      total: 0
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
    }),
    getColumns() {
      return [
        {
          title: '机构',
          field: 'organizationText',
          width: '20%',
          visible: !this.isSubbranch,
        },
        {
          title: '时间',
          field: 'warningTime',
          width: '40%',
          visible: true,
        },
        {
          title: '监督类型',
          field: 'analysisItemText',
          width: '20%',
          visible: true,
        },
        {
          title: '预警内容',
          field: 'description',
          width: '20%',
          visible: true,
        },
        {
          title: '审核状态',
          field: 'reviewStatusText',
          width: '20%',
          visible: this.isSubbranch,
        },
      ].filter((e) => e.visible);
    },
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    titleClick() {
      this.$refs.SafeSupervision.openDialog({
        query: {
          status: '02',
          reviewStatus: '01'
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped></style>